import { useContext, useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import UIContext from '../Context/UiContext';
import useAuth from '../Hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faXmark, faMessage, faChevronDown, faClipboardUser, faClipboardQuestion, faCalculator, faUserGroup,faCreditCard } from '@fortawesome/free-solid-svg-icons';
import jbLogo from '../../img/jb-logo.png';
import { faInstagram, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const SideMenu = () => {

  const { style, setStyle, socialShow, setSocialShow, setShow, setAccShow, setSubAlert, instaMenu,BankcardMenu, setBankcardMenu, setInstaMenu, whatsappMenu, setWhatsappMenu, dark, setDetShow, setInqShow, setWhtData, setAccData, setSocailCh } = useContext(UIContext);

  const { auth } = useAuth();
  
  const [role, setRole] = useState(auth?.role || localStorage.getItem('role'));  // Fallback to localStorage

  useEffect(() => {
      setRole(localStorage.getItem('role'));  // Update when auth changes
  }, []);

  const css = {
    width: "0px",
  };
  const dcss = {
    width: "100%",
  }

  // const [show, setShow] = useState();
  const smsShow = {
    display: "block",
  };
  const smsHide = {
    display: "none",
  }
  const toggleSocailShow = () => {
    setSocialShow(!socialShow);
    !socialShow && setInstaMenu(false);
    !socialShow && setWhatsappMenu(false);
  }

  const toggleInstaMenu = () => {
    setInstaMenu(!instaMenu);
    !instaMenu && setSocialShow(false);
    !instaMenu && setWhatsappMenu(false);
  }

  const toggleWhatsappMenu = () => {
    setWhatsappMenu(!whatsappMenu);
    !whatsappMenu && setSocialShow(false);
    !whatsappMenu && setInstaMenu(false);
  }
  const toggleBankcardMenu = () => {
    setBankcardMenu(!BankcardMenu);
    !BankcardMenu && setSocialShow(false);
    !BankcardMenu && setWhatsappMenu(false);
  }

  const stopPropagate = (event) => {
    event.stopPropagation();
  }

  const stopPropagat = (eve) => {
    eve.stopPropagation();
  }

  const changeStyle = () => {
    setStyle(!style);
  };

  return (

    <>
      <div id='mobile-menu' className='md:hidden h-full bg-middle-green' style={style ? css : dcss}>
        <nav className=''>
          <ul className='mobile-menu list-none p-0 m-0'>
            <span href="" className='text-white text-base lg:text-lg closebtn' onClick={changeStyle}><li className='p-4'><FontAwesomeIcon icon={faXmark} /></li></span>
            <NavLink to='/' className='text-white text-base lg:text-lg'><li className='p-4 hover:bg-middle-yellow border-b border-b-middle-green hover:border-y-white select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faHouse} /><span className='mr-3'>خانه</span></li></NavLink>
            <NavLink to='/number-inquiry' className='text-white text-base lg:text-lg'><li className='p-4 hover:bg-middle-yellow border-y border-y-middle-green hover:border-y-white select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faClipboardQuestion} /><span className='mr-3'>استعلام موبایل</span></li></NavLink>
            <NavLink to='/social-inquiry' className='text-white text-base lg:text-lg'><li className='p-4 hover:bg-middle-yellow border-y border-y-middle-green hover:border-y-white select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faClipboardUser} /><span className='mr-3'>استعلام نام کاربری</span></li></NavLink>
            <NavLink to='/account-details' className='text-white text-base lg:text-lg'><li className='p-4 hover:bg-middle-yellow border-y border-y-middle-green hover:border-y-white select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faClipboardUser} /><span className='mr-3'>فالوئر و فالوئینگ</span></li></NavLink>
            <NavLink to='/number-submit' className='text-white text-base lg:text-lg'><li className='p-4 hover:bg-middle-yellow border-y border-y-middle-green hover:border-y-white select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faClipboardQuestion} /><span className='mr-3'>معرفی شماره</span></li></NavLink>

          </ul>
        </nav>
      </div>

      <div className='hidden fixed md:flex flex-col min-h-screen top-0 bottom-0 right-0 bg-middle-green dark:bg-dark-blue rounded-l-none desk-sidebar w-52'>
        <div className={`${dark ? "bg-dark-blue" : "bg-grad"}`}>
          <Link to='/'>
            <div className='text-white sticky top-0 text-base lg:text-lg px-3 py-3 select-none'>
              <img className='inline-block' src={jbLogo} alt='logo' width={40} height={40} onClick={changeStyle} />
              <span className='hidden sm:inline-block mb-0 mr-2 text-white text-xl font-semibold'>سیستم استعلام</span>
            </div>
          </Link>
        </div>
        <nav className='md:overflow-y-auto scroll-side'>
          <ul className='list-none p-0 m-0'>
            <NavLink to='/' className='text-white text-base lg:text-lg'><li className='p-3 border-b border-b-middle-green dark:border-b-dark-blue select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faHouse} /><span className='mr-3'>خانه</span></li></NavLink>
             {/* <NavLink to='/number-submit' className='text-white text-base lg:text-lg'><li className='p-3 border-b border-b-middle-green select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faCalculator} /><span className='mr-3'>معرفی شماره</span></li></NavLink>  */}
            <li onClick={toggleSocailShow} className={`text-white text-base lg:text-lg border-y border-y-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in noSelect ${socialShow ? "bg-light-green border-y-bg-light-green" : 'border-y-middle-green'}`}>
              <div className='flex items-center p-3'>
                <div className='flex items-center'><FontAwesomeIcon icon={faCalculator} /></div>
                <span className='mr-3'>استعلام موبایل</span>
                <div className='grid place-content-center select-none text-base text-white mr-4'><FontAwesomeIcon icon={faChevronDown} /></div>
              </div>
              <span className='' style={socialShow ? { display: 'block' } : { display: 'none' }}>
                <ul className='inner-menu p-0' style={socialShow ? smsShow : smsHide}>
                  <NavLink to='/number-inquiry' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setDetShow(true), setAccShow(true), setSubAlert(false), stopPropagate(event), setInqShow(false), setWhtData(null))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>بررسی شماره</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to='/input-history' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagate(event))}>
                    <li className='p-3 select-none transition-all duration-100 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3 pt-1'>
                        <span className=''>تاریخچه استعلام</span>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              </span></li>
{/* 
             <li onClick={toggleInstaMenu} className={`text-white text-base lg:text-lg border-y border-y-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in noSelect ${instaMenu ? "bg-light-green border-y-bg-light-green" : 'border-y-middle-green'}`}>
              <div className='flex items-center p-3'>
                <div className='flex items-center'><FontAwesomeIcon icon={faInstagram} /></div>
                <span className='mr-3'>اینستاگرام</span>
                <div className='grid place-content-center select-none text-base text-white mr-4'><FontAwesomeIcon icon={faChevronDown} /></div>
              </div>
              <span className='' style={instaMenu ? { display: 'block' } : { display: 'none' }}>
                <ul className='inner-menu p-0' style={instaMenu ? smsShow : smsHide}>
                  <NavLink to='/insta-inquiry' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setDetShow(true), setAccShow(true), setSubAlert(false), stopPropagate(event), setInqShow(false), setWhtData(null), setSocailCh("insta"))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>استعلام اکانت</span>
                      </div>
                    </li>
                  </NavLink> 
                   <NavLink to='/account-details' className='text-white text-base lg:text-lg' onClick={(eve) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagat())}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>فالوئر و فالوئینگ</span>
                      </div>
                    </li>
                  </NavLink> 
                   <NavLink to='/instagram-posts' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagat(event))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>پست اینستاگرام</span>
                      </div>
                    </li>
                  </NavLink> 
                  <NavLink to='/insta-history' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagate(event))}>
                    <li className='p-3 select-none transition-all duration-100 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3 pt-1'>
                        <span className=''>تاریخچه استعلام</span>
                      </div>
                    </li>
                  </NavLink> 
                   <NavLink to='/instagram-history' className='text-white text-base lg:text-lg' onClick={(eve) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagat(eve))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>تاریخچه فالوئر</span>
                      </div>
                    </li>
                  </NavLink> 
                </ul>
              </span></li>  */}
            <li onClick={toggleWhatsappMenu} className={`text-white text-base lg:text-lg border-y border-y-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in noSelect ${whatsappMenu ? "bg-light-green border-y-bg-light-green" : 'border-y-middle-green'}`}>
              <div className='flex items-center p-3'>
                <div className='flex items-center'><FontAwesomeIcon icon={faWhatsapp} /></div>
                <span className='mr-3'>واتساپ</span>
                <div className='grid place-content-center select-none text-base text-white mr-4'><FontAwesomeIcon icon={faChevronDown} /></div>
              </div>
              <span className='' style={whatsappMenu ? { display: 'block' } : { display: 'none' }}>
                <ul className='inner-menu p-0' style={whatsappMenu ? smsShow : smsHide}>
                  <NavLink to='/whatsapp-details' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setDetShow(true), setAccShow(true), setSubAlert(false), stopPropagate(event), setInqShow(false), setWhtData(null), setAccData([]))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>بررسی شماره</span>
                      </div>
                    </li>
                  </NavLink>
                  <NavLink to='/whatsapp-history' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagate(event))}>
                    <li className='p-3 select-none transition-all duration-100 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3 pt-1'>
                        <span className=''>تاریخچه استعلام</span>
                      </div>
                    </li>
                  </NavLink>
                </ul>
              </span>
              </li>
               {/* <li onClick={toggleBankcardMenu} className={`text-white text-base lg:text-lg border-y border-y-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in noSelect ${BankcardMenu ? "bg-light-green border-y-bg-light-green" : 'border-y-middle-green'}`}>
              <div className='flex items-center p-3'>
                <div className='flex items-center'><FontAwesomeIcon icon={faCreditCard} /></div>
                <span className='mr-3'>استعلام بانکی</span>
                <div className='grid place-content-center select-none text-base text-white mr-4'><FontAwesomeIcon icon={faChevronDown} /></div>
              </div>
              <span className='' style={BankcardMenu ? { display: 'block' } : { display: 'none' }}>
                <ul className='inner-menu p-0' style={BankcardMenu ? smsShow : smsHide}>
                   <NavLink to='/Bankcard' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setDetShow(true), setAccShow(true), setSubAlert(false), stopPropagate(event), setInqShow(false), setWhtData(null), setAccData([]))}>
                    <li className='p-3 select-none transition-all duration-200 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3'>
                        <span className=''>بررسی شماره کارت</span>
                      </div>
                    </li>
                  </NavLink> 
                   <NavLink to='/BankHistory' className='text-white text-base lg:text-lg' onClick={(event) => setShow((true), setAccShow(true), setSubAlert(false), stopPropagate(event))}>
                    <li className='p-3 select-none transition-all duration-100 ease-in hover:text-middle-yellow'>
                      <div className='flex items-center mr-6 pb-3 pt-1'>
                        <span className=''>سوابق بانکی</span>
                      </div>
                    </li>
                  </NavLink> 
                </ul>
              </span></li>  
             <NavLink to='/whatsapp-details' className='text-white text-base lg:text-lg'><li className='p-3 border-b border-b-middle-green dark:border-b-dark-blue select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false), setDetShow(true), setAccData([]))}><FontAwesomeIcon icon={faWhatsapp} /><span className='mr-3'>واتساپ</span></li></NavLink>  
            <li className='text-gray-300 text-base lg:text-lg p-3 border-b cursor-default border-b-middle-green dark:border-b-dark-blue select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faTelegram} /><span className='mr-3'>تلگرام</span></li>
            <li className='text-gray-300 text-base lg:text-lg cursor-default p-3 border-b border-b-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faTwitter} /><span className='mr-3'>توییتر</span></li>   */}
            {role === "admin" && <NavLink to='/users-list' className='text-white text-base lg:text-lg'><li className='p-3 border-b border-b-middle-green dark:border-y-dark-blue select-none transition-all duration-100 ease-in' onClick={() => setShow((true), setAccShow(true), setSubAlert(false))}><FontAwesomeIcon icon={faUserGroup} />
            <span className='mr-3'>لیست کاربران</span>
            </li>
            </NavLink>}
          </ul>
        </nav>
      </div>
    </>
  )
}

export default SideMenu;
