import { useState, useEffect } from "react";
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
// import { axiosPrivate } from "../Components/Api/axios";
import axiosInstance from "../Api/axios";
import MainComponent from '../user register/MainComponent';

function UsersList() {
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get(`/auth/list`, {
                    signal: controller.signal
                });
                
                console.log(response);
                
                // Ensure the response data is an array
                const userListData = Array.isArray(response?.data) ? response?.data : [];
                isMounted && setUserList(userListData);
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        getList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        };
    }, []);
    
    const totalPages = Math.ceil(userList.length / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // Pagination logic
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedUsers = userList.slice(startIndex, endIndex);

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
                            <FontAwesomeIcon icon={faClockRotateLeft} />
                        </span>
                        <h5 className="inline-flex items-center">لیست کاربران</h5>
                        <div className='pl-8 flex justify-end'>
                            <div className="py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-green-500 rounded select-none transition-all duration-200 ease-in">
                                <MainComponent />
                            </div>
                            {!loading && (
                                <div className='pr-6'>
                                    {userList.length > itemsPerPage && (
                                        <ReactPaginate
                                            className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                                            pageCount={totalPages}
                                            onPageChange={handlePageChange}
                                            forcePage={currentPage}
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            containerClassName={"pagination-container"}
                                            activeClassName={"active-page"}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                            {loading ? (
                                <div className='flex'>
                                    <p className='text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p>
                                </div>
                            ) : (
                                displayedUsers.map((user, i) => (
                                    <div key={i} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                        <div className="news-card-header col-span-3">
                                            <div className='flex md:flex-col lg:flex-row justify-between'>
                                                <div className='flex justify-between items-center mr-6 w-full'>
                                                    <div className='flex flex-col justify-between'>
                                                        <div className="flex">
                                                            <div className="md:ml-4">
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white"></p>
                                                            </div>
                                                            <div className="">
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    {user.name && <> نام کاربر: <span>{user.name}</span></>}
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    {user.email && <> ایمیل: <span>{user.email}</span></>}
                                                                </p>
                                                                <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    {user.role && <> دسترسی: <span>{user.role === "admin" ? "ادمین" : "کاربر"}</span></>}
                                                                </p>
                                                                <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                    تاریخ ثبت: {user.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}>{user.created_at}</span> : " نامشخص "}
                                                                </p>
                                                                {/* Display usage statistics */}
                                                                <div className="m-0 text-sm mt-1 text-gray-600 dark:text-white mt-2">
                                                                    <h6 className="text-sm font-semibold">میزان استفاده از خروجی ها:</h6>
                                                                    <ul className="list-disc pl-5">
                                                                        <li>خروجی موبایل: {user.mobile_usage}</li>
                                                                        <li>خروجی واتساپ: {user.whatsapp_usage}</li>
                                                                        <li>خروجی اینستاگرام: {user.instagram_usage}</li>
                                                                        {/* <li>خروجی تلگرام: {user.telegram_usage === null ? "ندارد" : user.telegram_usage}</li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UsersList;