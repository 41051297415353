import React, { useState, useEffect } from "react";
import { axiosPrivate } from "../Api/axios";
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../../CSS/UserProfile.css'; // Import the CSS file
import axios from "axios";

function UserProfile() {
  const [user, setUser] = useState({ name: "", email: "", password: "" });
  const [newValues, setNewValues] = useState({
    name: localStorage.getItem("username") || "",
    email: localStorage.getItem("email") || "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = useState(""); // State for error messages
  const [loading, setLoading] = useState(); // Initialize loading state

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const fetchUserData = async () => {
      setLoading(true);
      const token = localStorage.getItem('access');
      console.log('Token being sent:', token);
        if (!token) {
            setErrorMsg("Authentication required");
            setLoading(false);
            return;
        }
        try {
            const response = await axiosPrivate.get('/auth/user',{ signal: controller.signal });
            console.log('User data:', response.data);
            setUser(response.data); // Set user data
        } catch (error) {
            console.error("Error fetching user data:", error);
            setErrorMsg("Failed to fetch user data.");
        } finally {
            setLoading(false);
        }
    };

    fetchUserData();
    return () => {
      isMounted = false;
      isMounted && controller.abort();
  }
}, [ ]);





  
  const validateForm = () => {
    const { name, email, password } = newValues;
    if (!name || !email || !password) {
      setErrorMsg("لطفاً همه فیلدها را پر کنید.");
      return false;
    }
    if (!/^[^\d]/.test(name)) {
      setErrorMsg("نام کاربری نباید با عدد شروع شود.");
      return false;
    }
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setErrorMsg("لطفاً یک ایمیل معتبر وارد کنید.");
      return false;
    }
    if (password.length < 8) {
      setErrorMsg("رمز عبور حداقل باید 8 کاراکتر باشد.");
      return false;
    }
    setErrorMsg(""); // Clear previous error message
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSave = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!validateForm()) return; // Validate form before proceeding

    const updatedUser = {
      name: newValues.name,
      email: newValues.email,
      password: newValues.password,
    };

    axiosPrivate
      .post("/auth/user/update", updatedUser)
      .then(() => {
        setUser(updatedUser);
        localStorage.setItem("username", updatedUser.name);
        localStorage.setItem("email", updatedUser.email);
      })
      .catch((error) => {
        console.error("Error updating user data:", error);
        setErrorMsg("Failed to update user data. Please try again later.");
      })
  };

  return (
    <>
      <Header />

      <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-y-auto'>
        <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
          <span className='inline-block place-content-center mr-4 text-blue-800 text-lg ml-2'>
            <FontAwesomeIcon icon={faUser} />
          </span>
          <h3 className="inline-flex items-center">پروفایل</h3>
          <p className='inline-flex px-8'>
            مشخصات کاربر در این بخش قابل مشاهده است.
          </p>
          <div className="inline-flex mr-50">
          <div className="signupSection">
            <div className="info">
              <h2>نمایش اطلاعات کاربری</h2>
              <FontAwesomeIcon icon={faUser} className="icon" />
              {user ? (
                <div>
                  <p><strong>ID:</strong> {user.id}</p> 
                  <p><strong>نام:</strong> {user.name}</p>
                  <p><strong>ایمیل:</strong> {user.email}</p>
                  <p>
                      <strong>نوع دسترسی:</strong> 
                      {user.role === 'user' ? 'کاربر' : user.role === 'admin' ? 'ادمین' : 'نامشخص'}
                  </p>
                  <p><strong>میزان استفاده از سرویس موبایل:</strong> {user.mobile_usage || "استفاده نشده"}</p>
                  <p><strong>میزان استفاده از سرویس واتساپ:</strong> {user.whatsapp_usage || "استفاده نشده"}</p>
                  <p><strong>میزان استفاده از سرویس اینستاگرام:</strong> {user.instagram_usage || "استفاده نشده"}</p>
                  <p><strong>میزان استفاده از سرویس تلگرام:</strong> {user.telegram_usage|| "استفاده نشده"}</p> 
                </div>
              ) : (
                <p>در حال بارگیری</p>
              )}
            </div>
            
             <form className="signupForm" name="signupform" onSubmit={handleSave}>
              <h2>ویرایش پروفایل</h2>
              <ul className="noBullet">
                <li>
                  <input
                    type="text"
                    className="inputFields"
                    id="username"
                    name="name"
                    placeholder="نام کاربری"
                     autoComplete="current-name"
                    value={newValues.name}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li>
                  <input
                    type="email"
                    className="inputFields"
                    id="email"
                    name="email"
                    placeholder="ایمیل"
                    value={newValues.email}
                    autoComplete="current-email"
                    onChange={handleInputChange}
                    required
                  />
                </li>
                <li>
                  <input
                    type="password"
                    className="inputFields"
                    id="password"
                    name="password"
                    placeholder="پسورد"
                    autoComplete="current-password"
                    value={newValues.password}
                    onChange={handleInputChange}
                    required
                  />
                </li>
                
                <li id="center-btn">
                  <input
                    type="submit"
                    id="join-btn"
                    name="edit"
                    alt="edit"
                    value="ثبت تغییرات"
                  />
                </li>
              </ul>

              {errorMsg && (
                <div className="alert alert-danger" style={{ marginTop: "20px", color: 'red' }}>
                  {errorMsg}
                </div>
              )}
            </form> 
          </div>
        </div></div>
      </div>
    </>
  );
}

export default UserProfile;