import { createContext, useState, useEffect  } from "react";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const storedAccess = localStorage.getItem("access");
        return storedAccess
            ? { access: storedAccess, name: '', email: '', role: '' }
            : { access: '', name: '', email: '', role: '' };
    });

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;