import { useState, useContext } from 'react';
import { Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import UIContext from "../Context/UiContext";
import jbLogo from '../../img/jb-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleUser, faBell, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../Hooks/useAuth';
import SideMenu from './SideMenu';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function Header() {

    const { auth, style, setStyle, dark, setDark } = useContext(UIContext);
    
    const location = useLocation();

    const changeStyles = () => {
        setStyle(!style);
        console.log(style);
    };

    const [show, setShow] = useState(false);
    let toggleVisibility = (e) => {
        setShow(!show);
    }
    
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        setAuth({});
        localStorage.clear();
        navigate('/sign-in');
    }

    const goSignin = async () => {
        <Navigate to="/sign-in" state={{ from: location }} replace />
    }

    const darkModeHandler = () => {
        setDark(!dark);
        document.body.classList.toggle("dark");
    }
    const name = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    const role = localStorage.getItem('role');


    return (
        <div className='main-header bg-light-green dark:bg-dark-blue flex z-50 top-0 fixed w-full border-b-2'>
            <div className='hidden md:flex flex-grow'>
            </div>
            <div onClick={changeStyles} className='flex right-0 top-6 sm:static md:hidden w-1/6 sm:border-l-2 openbtn'>
                <div onClick={changeStyles} className='grid place-content-center text-white text-xl mx-auto'><FontAwesomeIcon icon={faBars} /></div>
            </div>
            <div className='flex absolute right-0 left-0 m-auto md:hidden sm:static grow md:grow-0 justify-center items-center md:-order-1 p-3 md:border-l-2'>
                <img src={jbLogo} alt='logo' width={40} height={48} onClick={changeStyles} />
            </div>
            <div className='flex grow md:grow-0 justify-center items-center md:-order-1 md:border-l-2' style={style ? { position: 'absolute', right: -9999999999 } : { position: 'absolute', right: 'inherit' }}>
                <SideMenu />
            </div>
            <div className='flex justify-center items-center absolute left-0 top-6 sm:static sm:border-r-2 p-x-4 w-40'>
                <button onClick={() => darkModeHandler()} aria-label="dark mode"> 
                    {dark ? <div className='grid place-content-center text-white font-semibold px-2 text-2xl'><FontAwesomeIcon icon={faSun} /></div>
                        : <div className='grid place-content-center text-white font-semibold px-2 text-2xl'><FontAwesomeIcon icon={faMoon} /></div>}
                </button>
                <div className='relative'>
                    <div className='cursor-pointer select-none flex justify-center items-center px-2' onClick={toggleVisibility}>
                        <div className='grid place-content-center text-white font-semibold text-2xl' ><FontAwesomeIcon icon={faCircleUser} /></div>
                         {/* <div className='text-white font-semibold'>ناحیه کاربری</div>  */}
                    </div>
                    {show
                        ?
                        (
                            <nav
                            className="absolute top-full left-1/2 transform -translate-x-1/5 rounded-md bg-middle-green p-2 z-50 shadow-lg w-64"
                        >
                            <ul className="list-none p-0 m-0">
                                {/* اطلاعات کاربر */}
                                <li className="border-b border-gray-200/20 p-2 cursor-default">
                                    <div className="text-sm text-white flex justify-between">
                                        <span>نام:</span>
                                        <span>{name}</span>
                                    </div>
                                    <div className="text-xs text-white/60 flex justify-between mt-1">
                                        <span>ایمیل:</span>
                                        <span>{email}</span>
                                    </div>
                                    <div className="text-xs text-white/60 flex justify-between mt-1">
                                        <span>دسترسی:</span>
                                        <span>{role}</span>
                                    </div>
                                </li>
                                {/* پروفایل */}
                                <li className="p-2 mt-1 hover:bg-gray-100/20 rounded-md transition-colors">
                                     <Link
                                        to="/profile"
                                        className="flex items-center text-white text-sm"
                                    >
                                        <div
                                            className="grid place-content-center text-white ml-2"
                                        >
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                        <span>پروفایل</span>
                                    </Link> 
                                </li>
                                {/* ورود یا خروج */}
                                {localStorage.getItem("access") ? (
                                    <li
                                        className="flex items-center border-t border-gray-200/20 p-2 mt-2 hover:bg-gray-100/20 rounded-md transition-colors"
                                        onClick={logout}
                                    >
                                        <div
                                            className="grid place-content-center text-white ml-2"
                                        >
                                            <FontAwesomeIcon icon={faRightFromBracket} />
                                        </div>
                                        <span>خروج</span>
                                    </li>
                                ) : (
                                    <li
                                        className="flex items-center border-t border-gray-200/20 p-2 mt-2 hover:bg-gray-100/20 rounded-md transition-colors"
                                        onClick={goSignin}
                                    >
                                        <div
                                            className="grid place-content-center text-white ml-2"
                                        >
                                            <FontAwesomeIcon icon={faRightFromBracket} />
                                        </div>
                                        <span>ورود</span>
                                    </li>
                                )}
                            </ul>
                        </nav>
                        
                        )
                        :
                        ''
                    }
                </div>
                <div className='grid place-content-center text-white font-semibold px-2 text-2xl' ><FontAwesomeIcon icon={faBell} /></div>
                {/* <div className='grid place-content-center text-white'><FontAwesomeIcon icon={faChevronDown} /></div> */}
            </div>
        </div>
    )
}

export default Header
