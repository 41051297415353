import React, { useState, useEffect } from 'react';
import { MelallColorIcon, ToseeTaavonColorIcon, SepahMergedMehrEghtesadColorIcon, ResalatColorIcon, PostColorIcon, SepahMergedGhavaminColorIcon, SanatMadanColorIcon, SarmayehColorIcon, ToseeSaderatColorIcon, MaskanColorIcon, BankMarkaziColorIcon, AyandehColorIcon, EghtesadNovinColorIcon, SaderatColorIcon, SepahMergedAnsarColorIcon, PasargadColorIcon, IranZaminColorIcon, ShahrColorIcon, ParsianColorIcon, ToseeColorIcon, KarafarinColorIcon, DeyColorIcon, TejaratColorIcon, SepahMergedKosarColorIcon, GardeshgariColorIcon, SepahColorIcon, RefahColorIcon, KeshavarziColorIcon, SepahMergedHekmatColorIcon, MelliColorIcon, MehrIranColorIcon, MellatColorIcon, SamanColorIcon, SinaColorIcon } from '@snapp-store/iranian-banks-react-icons';
import Header from "../Header/Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import '../../CSS/cardframe.css'; 
import { axiosPrivate } from '../Api/axios';



const bankMapping = {
    "627412": { name: "بانک اقتصاد نوین", Icon: EghtesadNovinColorIcon },
    "207177": { name: "بانک توسعه صادرات ایران", Icon: ToseeSaderatColorIcon },
    "627648": { name: "بانک توسعه صادرات ایران", Icon: ToseeSaderatColorIcon },
    "627381": { name: "بانک انصار", Icon: SepahMergedAnsarColorIcon },
    "502229": { name: "بانک پاسارگاد", Icon: PasargadColorIcon },
    "639347": { name: "بانک پاسارگاد", Icon: PasargadColorIcon },
    "505785": { name: "بانک ایران زمین", Icon: IranZaminColorIcon },
    "502806": { name: "بانک شهر", Icon: ShahrColorIcon },
    "622106": { name: "بانک پارسیان", Icon: ParsianColorIcon },
    "639174": { name: "بانک پارسیان", Icon: ParsianColorIcon },
    "627884": { name: "بانک پارسیان", Icon: ParsianColorIcon },
    "502908": { name: "بانک توسعه تعاون", Icon: ToseeTaavonColorIcon },
    "628157": { name: "موسسه اعتباری توسعه", Icon: ToseeColorIcon },
    "639194": { name: "بانک کارآفرین", Icon: KarafarinColorIcon },
    "502910": { name: "بانک کارآفرین", Icon: KarafarinColorIcon },
    "502938": { name: "بانک دی", Icon: DeyColorIcon },
    "505416": { name: "بانک گردشگری", Icon: GardeshgariColorIcon },
    "627353": { name: "بانک تجارت", Icon: TejaratColorIcon },
    "505801": { name: "موسسه اعتباری کوثر", Icon: SepahMergedKosarColorIcon },
    "589210": { name: "بانک سپه", Icon: SepahColorIcon },
    "589463": { name: "بانک رفاه کارگران", Icon: RefahColorIcon },
    "603770": { name: "بانک کشاورزی", Icon: KeshavarziColorIcon },
    "639217": { name: "بانک کشاورزی", Icon: KeshavarziColorIcon },
    "636949": { name: "بانک حکمت ایرانیان", Icon: SepahMergedHekmatColorIcon },
    "603799": { name: "بانک ملی", Icon: MelliColorIcon },
    "610433": { name: "بانک ملت", Icon: MellatColorIcon },
    "991975": { name: "بانک ملت", Icon: MellatColorIcon },
    "606373": { name: "موسسه قرض الحسنه مهر ایران", Icon: MehrIranColorIcon },
    "621986": { name: "بانک سامان", Icon: SamanColorIcon },
    "505416": { name: "بانک سینا", Icon: SinaColorIcon },
    "636214": { name: "بانک آینده", Icon: AyandehColorIcon },
    "636795": { name: "بانک مرکزی", Icon: BankMarkaziColorIcon },
    "628023": { name: "بانک مسکن", Icon: MaskanColorIcon },
    "603769": { name: "بانک صادرات", Icon: SaderatColorIcon },
    "639607": { name: "بانک سرمایه", Icon: SarmayehColorIcon },
    "627961": { name: "بانک صنعت و معدن", Icon: SanatMadanColorIcon },
    "639599": { name: "بانک قوامین", Icon: SepahMergedGhavaminColorIcon },
    "639370": { name: "بانک مهر اقتصاد", Icon: SepahMergedMehrEghtesadColorIcon },
    "627760": { name: "پست بانک", Icon: PostColorIcon },
    "504172": { name: "بانک رسالت", Icon: ResalatColorIcon },
    "606256": { name: "موسسه اعتباری ملل", Icon: MelallColorIcon },
};
const backgroundStyles = {
    "502229": { background: '#2c3e50' }, // Dark background for this prefix
    "207177": { background: '#3498db' }, // Example color
    "627381": { background: '#e74c3c' }, // Example color
    "639347": { background: '#2c3e50' }, // Another example
    // Add more mappings as needed...
};



const BankCardChecker = () => {
    const [cardNumber, setCardNumber] = useState('');
    const [bankData, setBankData] = useState(null); // Shows the bank details
    const [accountHolderName, setAccountHolderName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [validCardNumber, setValidCardNumber] = useState('');
    
   
    const handleInputChange = (e) => {
        const value = e.target.value;

        // Allow only numeric input and restrict the length to 16 digits
        if (/^\d*$/.test(value) && value.length <= 16) {
            setCardNumber(value);
            setErrorMessage(''); // Clear error message
        } else if (value.length > 16) {
            setErrorMessage("شماره کارت نمی تواند بیشتر از 16 عدد باشد!");
        } else if (/\D/.test(value)) {
            setErrorMessage("لطفا فقط اعداد را وارد کنید!");
        } else {
            setErrorMessage("");
        }
    };

    const validateCardNumber = (number) => {
        if (number.length === 16) {
            const prefix = number.substring(0, 6);
            const bankInfo = bankMapping[prefix];

            if (bankInfo) {
                setBankData(bankInfo);
                fetchAccountHolderName(number); // Fetch account holder name using the card number
                setValidCardNumber(number); // Save the valid card number for rendering
            } else {
                setErrorMessage("شماره کارت در سیستم بانکی وجود ندارد");
                setBankData(null);
            }
        } else {
            setErrorMessage("شماره کارت باید 16 عدد باشد" );
        }
    };
// '5022291350268532'
const fetchAccountHolderName = async (cardNumber) => {
    try {
        const response = await axiosPrivate.post(`/check/card/${cardNumber}`);
        console.log('Card checked:', response);
        if (response.data?.code === '300') {
            setErrorMessage("شماره کارت وجود ندارد و به طور خودکار دخیره شد")
            console.log("Card not available, attempting to add...");
            console.log(cardNumber);
            const addResponse = await axiosPrivate.post(`/add/card`,{
                input : cardNumber,
            });
            if(addResponse.status === "200"){
                alert("شماره کارت ذخیره شد")
            }
            console.log("Add card response:", addResponse);
        } else {
            setAccountHolderName(response.data?.name || "نام حساب نامشخص");
        }
    } catch (error) {
        if (error.response) {
            // Server responded with a status code other than 2xx
            console.error("Server error:", error.response.data);
            setErrorMessage("خطا در سمت سرور: " + error.response.data?.message);
        } else if (error.request) {
            // No response received from server
            console.error("No response from server");
            setErrorMessage("اتصال به سرور برقرار نشد. لطفاً دوباره تلاش کنید.");
        } else {
            // Something else caused the error
            console.error("Error:", error.message);
            setErrorMessage("خطایی رخ داده است: " + error.message);
        }
    }
};

    const handleSubmit = (e) => {
        
        e.preventDefault();
        validateCardNumber(cardNumber); // Validate and potentially set the card data
        // addCard(cardNumber);
        // checkCard(cardNumber);
    };

    // const checkCard = async (cardNumber) => {
        
    //     try {
    //          const response = await axiosPrivate.get(`/check/card/${cardNumber}`);
    //         console.log('Card checked:', response);
    //         return response;
    //     } catch (error) {
    //         setErrorMessage(error.response?.data?.message || "خطا در دریافت نام حساب");
    //     }
    // };

    // const addCard = async (cardNumber) => {
    //     const formData = new FormData();
    //     formData.append('input', cardNumber);
    
    //     try {
    //         const response = await axiosPrivate.post(`/add/card`, {
    //             body: formData,
    //         });
            
    //         console.log('Card added:', response);
    //         return response;
    //     } catch (error) {
    //         setErrorMessage(error.response?.data?.message || "خطا در اضافه کردن شماره کارت");
    //     }
    // };
    
    return (
        <div>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                    <div className="md:col-span-7 md:p-4 rounded-md">
                        <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'>
                            <FontAwesomeIcon icon={faCreditCard} />
                        </span>
                        <h5 className="inline-flex items-center">استعلام کارت بانکی</h5>
                        <div>
                            <h4 className="flex items-center justify-center">لطفا شماره کارت را وارد کنید.</h4>
                            <form onSubmit={handleSubmit} className="flex justify-center mt-3">
                                <div style={{ "direction": "ltr" }}>
                                    <input
                                        type="text"
                                        value={cardNumber}
                                        onChange={handleInputChange}
                                        placeholder="شماره کارت"
                                        className="border rounded px-3 py-2 mb-4 w-full"
                                    />
                                </div>
                                <button type="submit" className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 cursor-pointer items-center`}>
                                    جستجو
                                </button>
                            </form>
                            {errorMessage && (
                                <div className="error-message text-red-500 mt-2">{errorMessage}</div>
                            )}
                            
                            <div className="flex items-center justify-center mt-3">
                            
                                
                            </div><hr></hr>
                            
                            {bankData && validCardNumber && (
                                <BankCard cardNumber={validCardNumber} bankData={bankData} accountHolderName={accountHolderName} />
                            )}
                           
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const BankCard = ({ cardNumber, bankData, accountHolderName }) => {
    const BankIcon = bankData.Icon;
    const prefix = cardNumber.substring(0, 6);
    const cardStyle = backgroundStyles[prefix] || { background: '#2c3e50' };
    return (
        <div className="container">
            <div className="card">
                <div className="front" style={cardStyle}>
                    <div className="row justify-between">
                    {/* <img src={logo} className='map-img'width={2} alt="" /> */}
                        <BankIcon height={50} className="bank-icon" />
                    </div>
                    <div className="row name">
                        <p>{bankData.name}</p>
                    </div>
                    <div className="row card-no" style={{ "direction": "ltr" }}>
                        <p>{formatCardNumber(cardNumber)}</p>
                    </div>
                    <div className="row card-holder">
                        <p>نام صاحب سپرده :{accountHolderName}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const formatCardNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
};

export default BankCardChecker;