import { createContext, useState } from "react";
import contactImginsta from '../../img/hipster-avatar-image-vector.jpg';
const UIContext = createContext();

export const UIProvider = ({ children }) => {
    const [dark, setDark] = useState(false);
    const [style, setStyle] = useState(true);
    const [socialShow, setSocialShow] = useState(false);
    const [numInp, setNumInp] = useState();
    const [show, setShow] = useState(true);
    const [accShow, setAccShow] = useState(true);
    const [detShow, setDetShow] = useState(true);
    const [tableShow, setTableShow] = useState(true);
    const [socialCh, setSocailCh] = useState("");
    const [contactImg, setContactImg] = useState(contactImginsta);
    const [inpShow, setmInpShow] = useState(false);
    const [userName, setUserName] = useState();
    const [subAlert, setSubAlert] = useState(false);
    const [inqData, setInqData] = useState();
    const [whtData, setWhtData] = useState();
    const [instaMenu, setInstaMenu] = useState(false)
    const [whatsappMenu, setWhatsappMenu] = useState(false)
    const [BankcardMenu, setBankcardMenu] = useState(false)
    const [inpKind, setInpKind] = useState("id");
    const [inq, setInq] = useState();
    const [inqShow, setInqShow] = useState(false);
    const [accData, setAccData] = useState();
    const [instaCard, setInstaCard] = useState(false);

    return (
        <UIContext.Provider value={{ style , setStyle , instaCard, setInstaCard, inqShow, setInqShow, socialShow , setSocialShow,BankcardMenu , setBankcardMenu, show, setShow, detShow, setDetShow, tableShow, inqData, setInqData, instaMenu, setInstaMenu, inq, setInq,
            setTableShow, socialCh, setSocailCh, contactImg, setContactImg, numInp, setNumInp, inpShow, setmInpShow, userName, setUserName, accShow, setAccShow, subAlert, setSubAlert, inpKind, setInpKind, dark, setDark, whtData, setWhtData,
            accData, setAccData, whatsappMenu, setWhatsappMenu }}>
            {children}
        </UIContext.Provider>
    );
};

export default UIContext;
