import { useContext, useState, useEffect, useRef } from "react";
import { axiosPrivate } from "../Api/axios";
import UIContext from '../Context/UiContext';
import { faClipboardQuestion} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BulkInp from "../number page/BulkInp";
import Result from "../Results";

function InstaInp() {

    const { setDetShow, instaCard, setInstaCard, setSocailCh, setContactImg, inqData, accData, setAccData, inpKind, setInqData, inq, setInq, subAlert, setInpKind, setShow, setTableShow, setSubAlert, show, tableShow, inqShow, setInqShow } = useContext(UIContext);
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userNum, setUserNum] = useState(null);
    const inputRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShow(false);
        setTableShow(true);
        setInput(userNum);
        setSubAlert(true);
        setInqShow(true);
        setInstaCard(true);
        setSocailCh("insta");
        inputRef.current.value = '';
    };

    const dontHandle = () => {
        alert("لطفا ورودی را مشخص کنید")
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
    
        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/check/${inpKind}/${input}`, {
                    signal: controller.signal
                });
                console.log(response);
                response?.data?.code == 403 && setLoading(false);
                if (isMounted) {
                    setInqData(response?.data);
                    if (response?.data?.code === "200") {
                        setInq(response?.data?.data[0]);
                    }
                }
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        }
    
        input && getList();
    
        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [input, inpKind]);
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                setLoading(true);
                const res = await axiosPrivate.post(`/add/${inpKind}`, { input });
                setLoading(false);
                console.log(res);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };

        inqData?.code === "300" && MySubmit();
        console.log(inqData?.code);
        inqData?.code === "200" && setAccData(inqData?.data[0]);

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, inqData]);

    const reportingDetails = () => {
        setDetShow(false);
    };
    return (
        <section className="inq-result">
            {
                !instaCard ? <div className="">
                    <span className='inline-block  place-content-center mr-4 text-blue-800 dark:text-blue-300 text-lg ml-2'><FontAwesomeIcon icon={faClipboardQuestion} /></span>
                    <h5 className="inline-flex items-center">استعلام شماره</h5>
                    <div className="">
                        <div className="flex items-center px-8">
                            <p className="m-0">نوع استعلام را مشخص کنید:</p>
                            <ul className="m-0 flex">
                                <li onClick={() => { setInpKind("id"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "id" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>شناسه کاربری </span></li>
                                <li onClick={() => { setInpKind("username"); }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "username" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`}><span>نام کاربری </span></li>
                            </ul>
                        </div>
                        <div className="mt-4">
                            <div className="lg:w-1/2 inq-holder">
                                <div className="mx-auto text-center">
                                    <form onSubmit={handleSubmit}>
                                        <label className="ml-2">ورود مشخصه تکی:</label>
                                        <input type="text" pattern="^\+?(\d{1,4})?\s?(\(?\d{1,4}\)?)?[\s.-]?\d{1,4}([\s.-]?\d{1,4}){1,3}$" ref={inputRef} title="شماره موبایل را به صورت صحیح وارد کنید." onChange={(e) => setUserNum(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                        {<button type={`${userNum ? "submit" : "button"}`} onClick={userNum ? console.log("submit") : dontHandle} className={`py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer`}>ثبت</button>}
                                    </form>
                                </div>
                            </div>
                            <BulkInp />
                        </div>
                    </div>
                </div>
                    : <Result/>
            }
        </section>
    )

}

export default InstaInp
