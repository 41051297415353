import React, { useState } from 'react';
import axiosPrivate from '../Api/axios';


const CreateUserButton = () => {
    const [name, setName] = useState(''); // Changed from username to name
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [role, setRole] = useState(''); // Defaulted to 'admin'
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const createUser = async () => {
        

        // Prepare form data
        const formData = new URLSearchParams();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('c_password', cPassword); // For confirming password
        formData.append('role', role);

        try {
            const response = await axiosPrivate.post(`/auth/register`, formData, {
                // headers: {
                //     'Content-Type': 'application/x-www-form-urlencoded', // Content type for urlencoded
                // },
            });
            if (password !== cPassword) {
                setError("Passwords do not match.");
                 return;} // Early exit from function
            if (response.status === true) {
                    setSuccess('کاربر با موفقیت ایجاد شد!');
                    setError('');
                    // Reset form fields after successful registration
                    setName('');
                    setEmail('');
                    setPassword('');
                    setCPassword('');
                    setRole('user');
                }
            
        } catch (err) {
            setError('Failed to register user: ' + (err.response?.data?.message || "Unknown error"));
            setSuccess('');
        }
    };

    return (
        <>
        <div>
            
            <h3>ثبت نام کاربر</h3>
            
            <input
                type="username"
                id='username'
                placeholder="نام کاربر"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                type="email"
                id='email'
                placeholder="ایمیل"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                id='pass'
                placeholder="پسورد"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <input
                type="password"
                placeholder="تایید پسورد"
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
            />
            <p>نوع دسترسی را مشخص کنید:</p>
             <select 
             value={role} 
             id="userRole"
             onChange={(e)=>setRole(e.target.value)}>
                <option value="user">کاربر </option>
                <option value="admin">ادمین</option>
            </select>
            <button type="button"  onClick={createUser} className="py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-green-500 rounded select-none transition-all duration-200 ease-in">
                ساخت کاربر</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
        </div></>
    );
};


export default CreateUserButton;