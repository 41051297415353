import { useState, useEffect } from "react";
import Header from "../Header/Header";
import ReactPaginate from 'react-paginate';
import { axiosPrivate } from "../Api/axios";
import { faClockRotateLeft, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../CSS/pagination.css';

function BankHistory() {
    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;
    // دریافت لیست کارت‌ها از API
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const fetchList = async () => {
            setLoading(true);
            try {
                const response = await axiosPrivate.get('/list/card', { signal: controller.signal });
                console.log("list of cards", response)
                setNumList(response?.data?.slice(0).reverse());
                
            } catch (err) {
                console.error("Error fetching card list:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, []);

    // محاسبه تعداد صفحات
    useEffect(() => {
        setTotalPages(Math.ceil(numList.length / itemsPerPage));
    }, [numList]);

    // محاسبه آیتم‌های صفحه فعلی
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = numList.slice(startIndex, endIndex);

    // تغییر صفحه
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // حذف کارت
    const handleDelete = async (card) => {
        try {
            const response = await axiosPrivate.post('/remove/card', { input: card });
            if (response.status === 200) {
                setNumList((prevList) => prevList.filter(item => item.card !== card));
            }
        } catch (err) {
            console.error("Error deleting card:", err);
        }
    };

    return (
        <>
        <Header />
        <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                    <h5 className="inline-flex items-center">تاریخچه کارت‌های بانکی</h5>
                    <div className="flex justify-end">
                        <div>
                            {numList.length > itemsPerPage && (
                                <ReactPaginate
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    forcePage={currentPage}
                                    previousLabel={"<<"}
                                    nextLabel={">>"}
                                    breakLabel={"..."}
                                    containerClassName={"pagination-container"}
                                    pageLinkClassName={"page-link"}
                                    activeLinkClassName={"active"}
                                    previousLinkClassName={"page-link"}
                                    nextLinkClassName={"page-link"}
                                    disabledClassName={"disabled"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                        {loading ? (
                            <p className='text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p>
                        ) : (
                            subset.map((card, index) => (
                                <div key={index} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <p className="text-sm text-gray-600 dark:text-white">شماره کارت: {card.card}</p>
                                            <p className="text-sm text-gray-600 dark:text-white">تاریخ ثبت: {card.created_at}</p>
                                            <p className="text-sm text-gray-600 dark:text-white">آخرین آپدیت: {card.updated_at}</p>
                                            <p className="text-sm text-gray-600 dark:text-white">نام صاحب حساب :{card.name}</p>
                                            <p className="text-sm text-gray-600 dark:text-white"> نام بانک: {card.bankName}</p>
                                        </div>
                                        <button
                                            className="py-1 px-2 bg-red-500 text-white rounded hover:bg-red-700 transition-all duration-200 ease-in"
                                            onClick={() => handleDelete(card.card)}
                                        >
                                            حذف
                                        </button>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default BankHistory;
