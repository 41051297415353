import axios from 'axios';

const BASE_URL = 'https://api.copy.saber24.ir';

// Create an instance of axios
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Create a private axios instance with dynamic token retrieval
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {  
        'Content-Type': 'application/json' },
    // withCredentials: true
});

// Add an interceptor to inject the token dynamically into the request
axiosPrivate.interceptors.request.use(
    config => {
        const token = localStorage.getItem('access');
        if (token) {
             config.headers.Authorization = `Bearer ${token}`
        }  
        console.log(config);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
// Response interceptor for handling 401 errors
axiosPrivate.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401) {
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            alert('رمز عبور شما منقضی شد مجدد وارد شوید!')
            window.location.href ='/sign-in'; // هدایت به صفحه لاگین
        }
        return Promise.reject(error);
    }
);
export default axiosInstance;
