import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import App from './Components/App';
import { AuthProvider } from './Components/Context/AuthProvider';
import reportWebVitals from './reportWebVitals';
import {HashRouter } from 'react-router-dom';
import { UIProvider } from './Components/Context/UiContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HashRouter>
      <AuthProvider>
        <UIProvider>
          <App />
        </UIProvider>
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>
);

reportWebVitals();
